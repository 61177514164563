import React, { Component } from "react";
import { connect } from "react-redux";
import "./QuestionnaireSetting.scss";
import QuestionnaireTamplate from "./QuestionnaireTamplate/QuestionnaireTamplate";
import UserAPI from "../../../api/UserAPI";
import Loading from "../../Loading/loading";
import {
  saveProjectSurvey,
  getProjectSurvey,
  updateProjectSurvey,
  saveProjectSurveyMutex,
} from "../../../api/Questionnaire_setting/question_info";
import { prioritizeSurveyQueueItem } from "../../../redux/actions/survey";
import {
  getQuestionType,
  getQuestionTypeName,
} from "./Question_rules/question_type_rule";
import QuestionConfig from "./Question_config/question_config";
import QuestionTemplate from "./Question_template/question_template";
import QuestionAi from "./Question_ai/question_ai";
import QuestionInfo from "./Question_info/question_info";
import QuestionTypePopup from "./Question_type_popup/question_type_popup";
import { getQueryParameter } from "../../../util/utils";
import { message } from "antd";
import QuestionLogic from "./Questionnaire_logic/question_logic";
import ScoreList from "./Calculation_setting/Score_list";
import { question_authority_obj } from "./question_authority_configuration";
import { account_type_obj } from "../../../pages/User_info/Values/user_data";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
class QuestionnaireSetting extends Component {
  constructor(props) {
    super(props);
    this.is_matrix_list = [8, 9, 16, 22, 23];
    this.is_topx_list = [1];
    this.state = {
      loading: 0, //加载中 getProjectSurvey接口
      loading_save: 0, // 保存 saveProjectSurvey和updateProjectSurvey和saveProjectSurveyMutex和接口
      loadingText: "加载中",
      needRefresh: true,
      add_question_type: "", //新增的题目类型
      next_selected_question_type: "", // 将select的题目类型
      img1: "",
      img2: "",
      name: "QuestionnaireSetting",
      showQuestionInfo: true,
      showQuestionTypePopup: false, // 是否显示题目类型弹窗
      project_entry_number: 0,

      brand_id: localStorage.getItem("brand_id")
        ? localStorage.getItem("brand_id")
        : "", //品牌ID
      project_id: localStorage.getItem("project_id")
        ? localStorage.getItem("project_id")
        : "",
      survey_id: "",

      questionnaire_tamplate_flag: false, //模板是否显示
      currentQuestionIndex: -1, //文件配置的修改
      currentOptionIndex: -1, // 当前选项的配置
      is_option_config: false, //是否再操作选项的设置
      question_list_formal: [], // 后端传给前端的
      question_template: {
        question_id: "",
        question_type: "",
        name: "",
        image: { name: "", val: "" },
        sort: "",
        //右侧
        is_required: 1,
        is_back_allowed: 1,
        is_option_random: 0, //选项是否乱序
        question_group: 0, //题目组比，默认0
        is_group_random: 0, //题目组别之间是否乱序
        is_within_group_random: 0, //题目组内是否乱序
        is_question_active: 1, //题目上下架
        min_time: 0, //题目回答最短时间，单位s（秒）
        is_other: 0,
        min_name: "0",
        max_name: "0",
        min_value: 0,
        max_value: 0,
        min_option: 0,
        max_option: 0,
        answers: [],
      },
      hasProjectSurvey: false, //getProjectSurvey接口的返回
      has_fixed_list: [11, 12, 13],
      question_list_fixed: [
        //固定三题
        {
          question_id: "",
          question_type: 12,
          name: "您是否种草了这款产品?",
          image: { name: "", val: "" },
          sort: 0,
          is_required: 1,
          is_back_allowed: 1,
          is_option_random: 0,
          question_group: 0, //题目组比，默认0
          is_group_random: 0, //题目组别之间是否乱序
          is_within_group_random: 0, //题目组内是否乱序
          is_question_active: 1,
          min_time: 0,
          is_other: 0,
          min_name: "0",
          max_name: "0",
          min_value: 0,
          max_value: 0,
          min_option: 0,
          max_option: 3,
          answers: [
            {
              option_id: "",
              name: "种草",
              sort: 0,
              image: { name: "", val: "" },
              is_other: false,
              is_option_active: 1,
              content: "",
              mutex: [],
            },
            {
              option_id: "",
              name: "一般",
              sort: 1,
              image: { name: "", val: "" },
              is_other: false,
              is_option_active: 1,
              content: "",
              mutex: [],
            },
            {
              option_id: "",
              name: "不感兴趣",
              sort: 2,
              image: { name: "", val: "" },
              is_other: false,
              is_option_active: 1,
              content: "",
              mutex: [],
            },
          ],
        },
        {
          question_id: "",
          question_type: 13,
          name: "您是否愿意购买这款产品?",
          image: { name: "", val: "" },
          sort: 1,
          is_required: 1,
          is_back_allowed: 1,
          is_option_random: 0,
          question_group: 0, //题目组比，默认0
          is_group_random: 0, //题目组别之间是否乱序
          is_within_group_random: 0, //题目组内是否乱序
          is_question_active: 1,
          min_time: 0,
          is_other: 0,
          min_name: "0",
          max_name: "0",
          min_value: 0,
          max_value: 0,
          min_option: 0,
          max_option: 3,
          answers: [
            {
              option_id: "",
              name: "有下单意愿",
              sort: 0,
              image: { name: "", val: "" },
              is_other: false,
              is_option_active: 1,
              content: "",
              mutex: [],
            },
            {
              option_id: "",
              name: "需要时再考虑",
              sort: 1,
              image: { name: "", val: "" },
              is_other: false,
              is_option_active: 1,
              content: "",
              mutex: [],
            },
            {
              option_id: "",
              name: "优惠时再考虑",
              sort: 2,
              image: { name: "", val: "" },
              is_other: false,
              is_option_active: 1,
              content: "",
              mutex: [],
            },
          ],
        },
        {
          question_id: "",
          question_type: 11,
          name: "您在多大程度上愿意向您的朋友或同事推荐这个产品?",
          image: { name: "", val: "" },
          sort: 2,
          is_required: 1,
          is_back_allowed: 1,
          is_option_random: 0,
          question_group: 0, //题目组比，默认0
          is_group_random: 0, //题目组别之间是否乱序
          is_within_group_random: 0, //题目组内是否乱序
          is_question_active: 1,
          min_time: 0,
          is_other: 0,
          min_name: "强烈不推荐",
          max_name: "强烈推荐",
          min_value: 1,
          max_value: 10,
          min_option: 0,
          max_option: 0,
          answers: [],
        },
      ],
      question_list: [], //列表 前端本身在维护的
      option_list: [
        { value: "单选", key: 1 }, //key为questionn_types
        { value: "多选", key: 2 },
        { value: "简答", key: 3 },
        { value: "排序", key: 4 },
        { value: "打分", key: 5 },
        { value: "比重", key: 6 },
        { value: "滑条", key: 7 },
        { value: "矩阵单选", key: 8 },
        { value: "矩阵多选", key: 9 },
        { value: "图片题", key: 10 },
        { value: "矩阵打分", key: 14 },
        { value: "矩阵滑条", key: 15 },
        { value: "开放题", key: 16 },
        { value: "例举题", key: 18 },
        { value: "价格敏感度", key: 22 },
      ],
      canShowLogic: false,
      canShowScoreList: false,
      showDefaultLogic: false, // 如果默认三道没有被拖动
      question_config_tab: 0, // 选择题库or题目设置
      question_config_tab_arr: [
        { name: "题库", is_show: true },
        { name: "题目设置", is_show: false },
        { name: "AI生成", is_show: false },
      ],
      product_id_for_template: "", //模版需要的
      ai_selected_survey_option: [], //ai 下拉框选项
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      JSON.stringify(props.project_entry_number) !==
      JSON.stringify(state.project_entry_number_prev)
    ) {
      return {
        survey_id: props.survey_id,
        product_id_for_template: props.product_id_for_template || "",
        // needRefresh: true,
        project_entry_number: props.project_entry_number || 0, //question
        project_entry_number_prev: props.project_entry_number,
      };
    }
    return null;
  }
  componentDidMount() {
    this._isMounted = true;
    this.setState({
      survey_id: this.props.survey_id,
      project_entry_number: this.props.project_entry_number,
    });
    // this.init()
  }
  componentDidUpdate() {
    if (this._isMounted && this.state.needRefresh) {
      if (this.state.project_entry_number === 3) {
        this.init();
        this.setState({
          needRefresh: false,
        });
      }
    }
  }
  componentWillUnmount() {
    console.log("Component WILL UNMOUNT!");
    this.setState({
      survey_id: "",
    });
  }
  // 初始化问卷
  initSurvey() {
    //样品派送需要固定三题，问卷调研不需要
    if (localStorage.getItem("current_project_type") === "1") {
      this.setState({
        question_list: [...this.state.question_list_fixed],
        currentQuestionIndex: 0,
      });
    } else {
      this.setState({
        question_list: [],
      });
    }
  }
  init() {
    if (window.location.search.indexOf("survey_id") !== -1) {
      let survey_id = getQueryParameter("survey_id");
      this.setState({ survey_id: survey_id }, () => {
        this.props.getData({
          // 尝试传一下survey_id
          project_entry_number: 3,
          survey_id: this.state.survey_id,
        });
        this.getProjectSurvey();
        this.props.prioritizeSurveyQueueItem(survey_id);
      });
    } else {
      this.getProjectSurvey();
      this.props.prioritizeSurveyQueueItem(this.props.survey_id);
      // this.initSurvey()
    }
  }
  prev() {
    //上一步
    this.props.getData({
      project_entry_number: 2,
      survey_id: this.state.survey_id, // 结局从产品列表跳转返回上一步没有survey_id
    });
    this.setState({
      project_entry_number: 2,
    });
  }
  // 判断子字符串在字符串中出现的次数
  countOccurrences(str, substr) {
    const regex = new RegExp(substr, "g"); // 创建全局匹配的正则表达式
    const matches = str.match(regex); // 使用 match 方法匹配子字符串

    return matches ? matches.length : 0; // 返回匹配到的次数，如果没有匹配到则返回 0
  }
  // 去除题目的最外侧<p>标签
  formatQuestionName(question_name) {
    let start_p = "<p>";
    let end_p = "</p>";
    let tmp = question_name.length - end_p.length;
    if (
      question_name.indexOf(start_p) === 0 &&
      question_name.lastIndexOf(start_p) === 0 &&
      question_name.indexOf(end_p) === tmp &&
      question_name.lastIndexOf(end_p) === tmp
    ) {
      return question_name.replace(/^(<p>)/, "").replace(/(<\/p>)$/, "");
    } else {
      return question_name;
    }
  }
  // 要传给后端的对象
  getQuestionList() {
    let tmpList = [];
    let has_matrix_list = [
      "matrix_single",
      "matrix_multiple",
      "matrix_blank",
      "matrix_psm",
      "blank_psm",
    ];
    for (let i = 0; i < this.state.question_list.length; i++) {
      if (
        !this.state.question_list[i].image.val &&
        this.state.question_list[i].name === ""
      ) {
        message.warning(
          `第${i + 1}个题目名称不可为空，请至少填写文字或上传图片`,
          2,
          () => {}
        );
        return -1;
      }
      if (this.state.question_list[i].question_type === 20) {
        if (!this.state.question_list[i].image.val) {
          message.warning(`第${i + 1}个题目请至少上传一张图片`, 2, () => {});
          return -1;
        }
      }
      let question_obj = JSON.parse(
        JSON.stringify(this.state.question_template)
      );
      question_obj.question_id = this.state.question_list[i].question_id;
      question_obj.question_type = this.state.question_list[i].question_type;
      // question_obj.name = this.formatQuestionName(this.state.question_list[i].name)
      question_obj.name = this.state.question_list[i].name;
      question_obj.image = this.state.question_list[i].image;
      question_obj.sort = i + 1;
      question_obj.is_required = this.state.question_list[i].is_required;
      question_obj.is_back_allowed =
        this.state.question_list[i].is_back_allowed;
      question_obj.is_other = this.state.question_list[i].is_other;
      question_obj.is_option_random =
        this.state.question_list[i].is_option_random;
      question_obj.question_group = this.state.question_list[i].question_group;
      question_obj.is_group_random =
        this.state.question_list[i].is_group_random;
      question_obj.is_within_group_random =
        this.state.question_list[i].is_within_group_random;
      question_obj.is_question_active =
        this.state.question_list[i].is_question_active;
      question_obj.min_time = this.state.question_list[i].min_time;
      let answers = this.state.question_list[i].answers;
      for (let j = 0, left_cnt = 0, right_cnt = 0; j < answers.length; j++) {
        if (!answers[j].image.val && answers[j].name === "") {
          message.warning(
            `第${i + 1}个题目的第${
              j + 1
            }个的选项名称不可为空，请至少填写文字或上传图片`,
            2,
            () => {}
          );
          return -1;
        }
        let mutex = [];
        for (let p = 0; p < answers[j].mutex.length; p++) {
          let mutex_index = answers[j].mutex[p] - 1;
          mutex.push(answers[mutex_index].option_id);
        }
        let answer_option = {
          option_id: answers[j].option_id,
          name: answers[j].name,
          sort: j + 1,
          image: answers[j].image,
          is_option_active: answers[j].is_option_active, // 所有题目都传这个参数
          option_position:
            this.is_matrix_list.includes(question_obj.question_type) ||
            this.is_topx_list.includes(question_obj.question_type)
              ? answers[j].option_position
              : "",
          content: answers[j].content,
          mutex: mutex,
          inconsistent_option_ids: answers[j].inconsistent_option_ids || "",
          is_other: answers[j].is_other || false,
        };
        if (
          has_matrix_list.includes(getQuestionType(question_obj.question_type))
        ) {
          if (answers[j].option_position === "left") {
            answer_option.sort = left_cnt + 1;
            left_cnt = left_cnt + 1;
          } else {
            answer_option.sort = right_cnt + 1;
            right_cnt = right_cnt + 1;
          }
        }
        question_obj.answers.push(answer_option);
      }

      if (
        this.state.question_list[i].question_type === 1 ||
        this.state.question_list[i].question_type === 12 ||
        this.state.question_list[i].question_type === 13
      ) {
        //单选题
        question_obj.is_option_random =
          this.state.question_list[i].is_option_random;
      } else if (
        this.state.question_list[i].question_type === 5 ||
        this.state.question_list[i].question_type === 7 ||
        this.state.question_list[i].question_type === 11 ||
        this.state.question_list[i].question_type === 14 ||
        this.state.question_list[i].question_type === 15 ||
        this.state.question_list[i].question_type === 19
      ) {
        //nps 矩阵打分 打分
        question_obj.min_value = this.state.question_list[i].min_value;
        question_obj.max_value = this.state.question_list[i].max_value;
        question_obj.min_name = this.state.question_list[i].min_name;
        question_obj.max_name = this.state.question_list[i].max_name;
      } else if (
        this.state.question_list[i].question_type === 2 ||
        this.state.question_list[i].question_type === 9 ||
        this.state.question_list[i].question_type === 18 ||
        this.state.question_list[i].question_type === 20
      ) {
        //多选题
        question_obj.min_option = this.state.question_list[i].min_option;
        question_obj.max_option = this.state.question_list[i].max_option;
        question_obj.max_name = this.state.question_list[i].max_name;
      } else if (this.state.question_list[i].question_type === 3) {
        //nps
        question_obj.min_value = this.state.question_list[i].min_value;
      } else if (this.state.question_list[i].question_type === 6) {
        //比重
        question_obj.max_value = this.state.question_list[i].max_value;
      } else if (this.state.question_list[i].question_type === 4) {
        // 排序题  设置最小选项  完成排序可不用全选的功能
        question_obj.min_option = this.state.question_list[i].min_option;
      }

      tmpList.push(question_obj);
    }
    return tmpList;
  }
  // 下一步 是到物流信息还是另一个页面
  nextStep() {
    let current_project_type =
      localStorage.getItem("current_project_type") || "";
    if (parseInt(current_project_type) === 2) {
      localStorage.removeItem("current_project_type");
      localStorage.removeItem("project_id");
      this.props.history.push(`/survey_list`);
    } else {
      this.props.getData({
        project_entry_number: 6,
        project_id: localStorage.getItem("project_id")
          ? localStorage.getItem("project_id")
          : "",
      });
    }
  }
  // 保存
  accomplish(t, setting_icon) {
    let tmpList = this.getQuestionList();
    console.log(tmpList);
    if (tmpList === -1) {
      return;
    }
    let isObjectValueEqual = this.isObjectEqual(
      tmpList,
      this.state.question_list_formal
    );

    // 现在可以保存空问卷
    // if (!this.state.question_list.length) {
    //   message.warning("未设置调研问卷", 1, () => { });
    //   return;
    // }
    let tmpList2 = this.handle_question_list(tmpList);
    console.log(tmpList2);
    setTimeout(() => {
      if (!this.state.hasProjectSurvey) {
        //如果没有survey_id，按完成 或 上一步 ，会调保存问卷信息的接口
        this.saveProjectSurvey(tmpList2, t, setting_icon);
      } else if (this.state.hasProjectSurvey && !isObjectValueEqual) {
        //如果有survey_id  且列表发生变化 ，按完成 或 上一步 ，会调更新问卷信息的接口
        this.updateProjectSurvey(tmpList2, t, setting_icon);
      } else if (
        this.state.hasProjectSurvey &&
        setting_icon === "logic_setting"
      ) {
        //问题逻辑
        //如果有survey_id  且列表发生变化 ，按完成 或 上一步 ，会调更新问卷信息的接口
        this.updateProjectSurvey(tmpList2, t, setting_icon);
      } else if (
        this.state.hasProjectSurvey &&
        isObjectValueEqual &&
        t === "next"
      ) {
        // 如果未发生修改边，但点击的是下一步
        this.nextStep();
      } else {
        message.warning(`未发生修改变化`, 2, () => {});
      }
    }, 0);
  }
  isObjectEqual(a, b) {
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);
    if (aProps.length != bProps.length) {
      return false;
    }
    for (var i = 0; i < aProps.length; i++) {
      var propName = aProps[i];

      var propA = a[propName];
      var propB = b[propName];
      // 这里忽略了值为undefined的情况
      // 故先判断两边都有相同键名
      if (!b.hasOwnProperty(propName)) return false;
      if (propA instanceof Object) {
        if (this.isObjectEqual(propA, propB)) {
          // return true     这里不能return ,后面的对象还没判断
        } else {
          return false;
        }
      } else if (propA !== propB) {
        return false;
      } else {
      }
    }
    return true;
  }
  //  增删改
  handle_question_list(tmpList) {
    let question_list_formal = JSON.parse(
      JSON.stringify(this.state.question_list_formal)
    );
    let question_list = JSON.parse(JSON.stringify(tmpList));

    if (!this.state.hasProjectSurvey) {
      // 第一次保存
      question_list.forEach((item, index) => {
        item.question_status = 1;
        item.option_status = 1;
      });
      return question_list;
    }

    // 增删改
    // status  0无修改 1 增加 2 删 3 改
    let current_question_id_list = [];
    let ans = [];
    let new_ans = [];
    for (let i = 0; i < question_list.length; i++) {
      current_question_id_list.push(question_list[i].question_id);
      if (question_list[i].question_id === "") {
        // 判断是否是新题
        question_list[i].question_status = 1;
        question_list[i].option_status = 1;
        new_ans.push(question_list[i]);
      }
    }
    for (let i = 0; i < question_list_formal.length; i++) {
      question_list_formal[i].question_status = 0; // 初始化
      question_list_formal[i].option_status = 0;
      let index = current_question_id_list.indexOf(
        question_list_formal[i].question_id
      );
      if (index === -1) {
        // 删除
        question_list_formal[i].question_status = 2;
        question_list_formal[i].option_status = 3;
        ans.push(question_list_formal[i]);
      } else if (index >= 0) {
        let {
          answers: answers_arr_formal,
          option_status: option_status_formal,
          question_status: question_status_formal,
          ...question_list_obj_formal
        } = question_list_formal[i];
        let {
          answers: answers_arr_cur,
          option_status: option_status_cur,
          question_status: question_status_cur,
          ...question_list_obj
        } = question_list[index];
        if (index === i) {
          // 题目位置一样
          let isObjectEqualForQuestion = this.isObjectEqual(
            question_list_obj_formal,
            question_list_obj
          );
          if (isObjectEqualForQuestion) {
            question_list[index].question_status = 0; // 题目一样
          } else {
            question_list[index].question_status = 3; // 题目不一样
          }
        } else {
          // 题目位置不一样
          question_list[index].question_status = 3; // 题目位置都不一样了
          // 判断result
        }
        let isObjectEqualForOption = this.isObjectEqual(
          answers_arr_formal,
          answers_arr_cur
        );
        if (isObjectEqualForOption) {
          question_list[index].option_status = 0;
        } else {
          question_list[index].option_status = 3;
        }
        ans.push(question_list[index]);
      }
    }
    return [...ans, ...new_ans];
  }
  //保存问卷信息
  async saveProjectSurvey(question_list, t, setting_icon) {
    let project_id = localStorage.getItem("project_id")
      ? localStorage.getItem("project_id")
      : "";
    let brand_id = parseInt(this.state.brand_id);
    this.setState({
      loading_save: 1,
      loadingText: "保存中，请耐心等待",
    });
    try {
      const response = await saveProjectSurvey({
        project_id: project_id,
        brand_id: brand_id,
        question_list: question_list,
      });
      if (response.status === 204) {
        this.setState({
          loading_save: 0,
          question_list: [],
        });
        return;
      }
      let survey_id = response.data.survey_id;
      let tmpList = JSON.parse(JSON.stringify(response.data.question_list));
      let tmpList_prev = JSON.parse(JSON.stringify(this.state.question_list));
      for (let i = 0; i < tmpList.length; i++) {
        let answers = tmpList[i].answers;

        for (let j = 0; j < answers.length; j++) {
          answers[j].is_other = false;
          if (j === answers.length - 1 && tmpList[i].is_other === 1) {
            // 有其他的选项
            answers[j].is_other = true;
          }
          // mutex
          let tmp = [];
          if (
            tmpList_prev[i]?.answers?.length &&
            tmpList_prev[i].answers[j]?.hasOwnProperty("mutex")
          ) {
            tmp = tmpList_prev[i].answers[j].mutex;
          }
          answers[j].mutex = tmp;
        }
      }
      this.setState(
        {
          loading_save: 0,
          survey_id: survey_id,
          question_list: tmpList,
        },
        () => {
          let tmpList = this.getQuestionList();
          let tmpList2 = this.handle_question_list(tmpList);
          setTimeout(() => {
            this.saveProjectSurveyMutex(tmpList2, setting_icon, t);
          }, 0);
          if (t === "next") {
            this.nextStep();
          }
        }
      );
    } catch (error) {
      this.setState({
        loading_save: 0,
        canShowLogic: false,
      });
      message.error("保存失败，请检查问卷");
      throw error;
    }
  }
  //更新问卷信息
  async updateProjectSurvey(question_list, t, setting_icon) {
    console.log(question_list);
    let project_id = localStorage.getItem("project_id")
      ? localStorage.getItem("project_id")
      : "";
    let brand_id = this.state.brand_id;
    let survey_id = this.state.survey_id;
    this.setState({
      loading_save: 1,
      loadingText: "更新中，请耐心等待",
    });
    try {
      const response = await updateProjectSurvey({
        survey_id: survey_id,
        project_id: project_id,
        brand_id: brand_id,
        question_list: question_list,
      });
      if (response.status === 204) {
        this.setState({
          loading_save: 0,
          question_list: [],
          question_list_formal: [],
        });
        return;
      }
      // survey_id = response.data.survey_id;
      let tmpList = JSON.parse(JSON.stringify(response.data.question_list));
      let tmpList_prev = JSON.parse(JSON.stringify(this.state.question_list));
      for (let i = 0; i < tmpList.length; i++) {
        let answers = tmpList[i].answers;

        for (let j = 0; j < answers.length; j++) {
          answers[j].is_other = false;
          if (j === answers.length - 1 && tmpList[i].is_other === 1) {
            // 有其他的选项
            answers[j].is_other = true;
          }
          // mutex
          let tmp = [];
          if (
            tmpList_prev[i]?.answers.length &&
            tmpList_prev[i]?.answers[j]?.hasOwnProperty("mutex")
          ) {
            tmp = tmpList_prev[i].answers[j].mutex;
          }
          answers[j].mutex = tmp;
        }
      }
      this.setState(
        {
          loading_save: 0,
          survey_id: survey_id,
          question_list: tmpList,
          question_list_formal: response.data.question_list,
          // canShowLogic: true
        },
        () => {
          let tmpList = this.getQuestionList();
          let tmpList2 = this.handle_question_list(tmpList);
          setTimeout(() => {
            this.saveProjectSurveyMutex(tmpList2, setting_icon, t);
          }, 0);
          if (t === "next") {
            this.nextStep();
          }
        }
      );
    } catch (error) {
      this.setState({
        loading_save: 0,
        canShowLogic: false,
      });
      message.error("保存失败，请检查问卷");
      throw error;
    }
  }
  // 为了解决之前遗留的bug
  // 之前为了方便直接去掉了第一个<p>和最后一个/p>
  // 因为wangeditor 只要点击，即使没有修改，也会生成p标签
  // 所以后台有 "您</p><p>可以点击" 这样的存在
  handleQuestionNameForRichtext(question_list) {
    for (let i = 0; i < question_list.length; i++) {
      let question_name = question_list[i].name;
      let start_p_val = "<p>";
      let end_p_val = "</p>";
      let tmp = question_name.length - end_p_val.length;
      let start_p = question_name.indexOf("<p>");
      let end_p = question_name.indexOf("</p>");
      if (
        end_p !== -1 &&
        this.countOccurrences(question_name, "</p>") !==
          this.countOccurrences(question_name, "<p>") &&
        question_name.lastIndexOf(end_p_val) === tmp
      ) {
        question_list[i].name = `<p>${question_name}`;
      } else if (
        start_p !== -1 &&
        this.countOccurrences(question_name, "</p>") !==
          this.countOccurrences(question_name, "<p>") &&
        question_name.indexOf(start_p_val) === 0
      ) {
        question_list[i].name = `${question_name}</p>`;
      } else if (start_p !== -1 && end_p !== -1 && end_p <= start_p) {
        question_list[i].name = `<p>${question_name}</p>`;
      }
    }
    return question_list;
  }
  // 获取问卷信息
  async getProjectSurvey(setting_icon) {
    let survey_id = this.state.survey_id;
    this.setState({
      loading: 1,
      loadingText: "加载中",
    });
    try {
      const response = await getProjectSurvey({
        // survey_id: 996
        survey_id: survey_id,
      });
      this.setState({
        loading: 0,
      });
      if (response.data.question_list.length === 0) {
        this.initSurvey();
        return;
      }
      let tmpList = this.handleQuestionNameForRichtext(
        JSON.parse(JSON.stringify(response.data.question_list))
      );
      this.setState({
        hasProjectSurvey: true,
        question_list_formal: tmpList, //正式的一份
      });
      for (let i = 0; i < tmpList.length; i++) {
        let answers = tmpList[i].answers;

        for (let j = 0; j < answers.length; j++) {
          answers[j].is_other = false;
          if (j === answers.length - 1 && tmpList[i].is_other === 1) {
            // 有其他的选项
            answers[j].is_other = true;
          }
          let mutex = [];
          for (let p = 0; p < answers[j].mutex.length; p++) {
            let mutex_option_id = answers[j].mutex[p];
            for (let pp = 0; pp < answers.length; pp++) {
              if (mutex_option_id === answers[pp].option_id) {
                mutex.push(pp + 1);
              }
            }
          }
          answers[j]["mutex"] = mutex;
        }
      }
      this.setState(
        {
          currentQuestionIndex:
            this.state.currentQuestionIndex === -1
              ? 0
              : this.state.currentQuestionIndex,
          question_list: tmpList, //正式copy的一份
          canShowLogic: setting_icon === "logic_setting" ? true : false,
        },
        () => {
          this.handleOptionList();
          if (this.state.canShowLogic) {
            this.props.getData({
              project_entry_number: 4,
            });

            this.setState({
              showQuestionInfo: false,
              canShowScoreList: false,
            });
          }
        }
      );
    } catch (error) {
      this.setState({
        loading: 0,
      });
      message.error("获取后台发生错误!");
      throw error;
    }
  }
  //保存项目选项互斥信息
  async saveProjectSurveyMutex(tmpList2, setting_icon, t) {
    let project_id = localStorage.getItem("project_id")
      ? localStorage.getItem("project_id")
      : "";
    let brand_id = parseInt(this.state.brand_id);
    this.setState({
      loading_save: 1,
    });
    try {
      const response = await saveProjectSurveyMutex({
        project_id: project_id,
        brand_id: brand_id,
        survey_id: this.state.survey_id,
        question_list: tmpList2,
      });
      this.setState({
        loading_save: 0,
      });
      message.success("保存成功", 2, () => {});
      if (response.status === 204) {
        this.initSurvey();
        return;
      }
      if (t === "next") {
      } else {
        this.getProjectSurvey(setting_icon);
      }
    } catch (error) {
      this.setState({
        loading_save: 0,
      });
      message.error("互斥信息后台发生错误");
      throw error;
    }
  }
  questionnaire_tamplate_isShow() {
    this.setState({
      questionnaire_tamplate_flag: !this.state.questionnaire_tamplate_flag,
    });
  }
  // 判断两个对象是否相等
  isObjectValueEqual(obj1, obj2) {
    if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
      return true;
    }
    return false;
  }
  // 是否能新增问题
  canAddQuestion() {
    let question_num_required =
      question_authority_obj.question_num[this.props.user.account_type];
    if (
      this.props.is_native &&
      this.state.question_list.length >= question_num_required
    ) {
      message.warning(
        `${
          account_type_obj[this.props.user.account_type]
        }最多可配置${question_num_required}道题目，请联系商务进行升级`
      );
      return false;
    }
    return true;
  }
  // 是否能新增 简答题、枚举题、开放题 问题
  canAddSelectedQuestion(selected_question_type) {
    if (
      this.props.is_native &&
      question_authority_obj.rule_3[this.props.user.account_type].need_obey
    ) {
      if (
        !question_authority_obj.rule_3[
          this.props.user.account_type
        ].question_type_list.includes(selected_question_type)
      ) {
        message.warning(
          `您的账号类型为${
            account_type_obj[this.props.user.account_type]
          }，只能添加类型为${question_authority_obj.rule_3[
            this.props.user.account_type
          ].question_type_list
            .map((ele) => getQuestionTypeName(getQuestionType(ele)))
            .join("，")}的题目,请联系商务进行升级`,
          2,
          () => {}
        );
        return false;
      }
    }
    // 用户可配置的简答题、枚举题、开放题数量
    let relative_question_list = [3, 18, 16];
    if (
      this.props.is_native &&
      question_authority_obj.rule_4[this.props.user.account_type].need_obey &&
      relative_question_list.includes(selected_question_type)
    ) {
      let relative_question_sum_current = 0;
      this.state.question_list.forEach((ele) => {
        if (relative_question_list.includes(ele.question_type)) {
          relative_question_sum_current += 1;
        }
      });
      if (
        relative_question_sum_current >=
        question_authority_obj.rule_4[this.props.user.account_type]
          .relative_question_sum
      ) {
        message.warning(
          `您的账号类型为${
            account_type_obj[this.props.user.account_type]
          }，可配置的${relative_question_list
            .map((ele) => getQuestionTypeName(getQuestionType(ele)))
            .join("，")}的数量之和不能超过 ${
            question_authority_obj.rule_4[this.props.user.account_type]
              .relative_question_sum
          }个,请联系商务进行升级`,
          2,
          () => {}
        );
        return false;
      }
    }
    return true;
  }
  //点击新增问答
  clickAddQuestionnaire() {
    if (!this.canAddQuestion()) {
      return;
    }
    this.setState({
      showQuestionTypePopup: true,
    });
  }

  logic_setting() {
    //逻辑设置页展示
    this.props.getData({
      project_entry_number: 4,
    });
    this.setState({
      project_entry_number: 4,
    });
  }

  //父亲修改题目配置
  editQuestionInfo(index) {
    let question_config_tab_arr = this.state.question_config_tab_arr;
    question_config_tab_arr.forEach((ele) => {
      if (ele.name === "题目设置") {
        ele.is_show = true;
      } else {
        ele.is_show = false;
      }
    });
    this.setState(
      {
        currentQuestionIndex: index,
        currentOptionIndex: -1,
        is_option_config: false,
        question_config_tab: 1,
        question_config_tab_arr: question_config_tab_arr,
      },
      () => {
        this.handleOptionList();
      }
    );
  }
  //爷爷修改问题题目
  handleQuestionName(value, index) {
    let tmpList = JSON.parse(JSON.stringify(this.state.question_list));
    tmpList[index].name = this.formatQuestionName(value);
    this.setState({
      question_list: tmpList,
    });
  }
  // 爷爷heatmap编辑选项
  handleQuestionOption(question_index, answers) {
    let tmpList = JSON.parse(JSON.stringify(this.state.question_list));
    tmpList[question_index].answers = answers;
    this.setState({
      question_list: tmpList,
    });
  }
  //爷爷修改问题选项名
  handleQuestionOptionName(
    question_index,
    option_index,
    option_value,
    option_position
  ) {
    let tmpList = JSON.parse(JSON.stringify(this.state.question_list));
    let confirmed_option_index = option_index;
    if (option_position) {
      //单选题多选题
      let option_left_index = -1;
      let option_up_index = -1;

      for (let i = 0; i < tmpList[question_index].answers.length; i++) {
        if (tmpList[question_index].answers[i].option_position === "left") {
          option_left_index++;
        } else if (
          tmpList[question_index].answers[i].option_position === "up"
        ) {
          option_up_index++;
        }
        if (option_position === "left" && option_left_index === option_index) {
          confirmed_option_index = i;
          break;
        } else if (
          option_position === "up" &&
          option_up_index === option_index
        ) {
          confirmed_option_index = i;
          break;
        }
      }
    }
    console.log("question_set");
    console.log(option_value, "option_value");
    tmpList[question_index].answers[confirmed_option_index].name = option_value;
    console.log(tmpList);
    this.setState(
      {
        question_list: tmpList,
      },
      () => {
        console.log(this.state.question_list);
      }
    );
  }
  // 爷爷删除选项
  handleDeleteOption(question_index, option_index, option_position) {
    console.log(option_index, "option_index");
    console.log(option_position, "option_position");
    let has_multiple_list = ["multiple"];
    let has_matrix_list = ["matrix_multiple"];
    let has_matrix_list_2 = ["matrix_score", "matrix_nps"];

    let tmpList = JSON.parse(JSON.stringify(this.state.question_list));
    let question_type_val = getQuestionType(
      tmpList[question_index].question_type
    );
    let confirmed_option_index = option_index;
    let option_left_index = -1;
    let option_up_index = -1;
    if (option_position) {
      //矩阵单选题多选题
      // 确定矩阵题型中 顶部、左部选项数量
      for (let i = 0; i < tmpList[question_index].answers.length; i++) {
        if (tmpList[question_index].answers[i].option_position === "left") {
          option_left_index++;
        } else if (
          tmpList[question_index].answers[i].option_position === "up"
        ) {
          option_up_index++;
        }
      }
      // 找到要删除的选项在数组中的下标
      let n = -1;
      for (let i = 0; i < tmpList[question_index].answers.length; i++) {
        if (
          tmpList[question_index].answers[i].option_position === option_position
        ) {
          n++;
          if (n === option_index) {
            confirmed_option_index = i;
            break;
          }
        }
      }
    }

    if (tmpList[question_index].is_other === 1) {
      //其他题 矩阵题不会走进来的
      //删除的体现是有其他的题型
      let answer_len = tmpList[question_index].answers.length;
      if (option_index === answer_len - 1) {
        tmpList[question_index].is_other = 0;
      }
    }
    // 去除互斥条件
    if (has_multiple_list.includes(question_type_val)) {
      //多选题
      let current_options = tmpList[question_index].answers; //当前题目的选项
      let option_id_index_mapper = {}; // 选项ID和索引的映射
      let count = 0;
      for (let i = 0; i < current_options.length; i++) {
        if (i !== option_index) {
          option_id_index_mapper[current_options[i].option_id] = count;
          count += 1;
        }
      }

      for (let i = 0; i < current_options.length; i++) {
        // 从记录中重新取出inconsistent_option_ids赋值于mutex

        let updated_mutex = [];
        let records = current_options[i].inconsistent_option_ids
          ? current_options[i].inconsistent_option_ids.split(",")
          : [];
        for (let j = 0; j < records.length; j++) {
          let option_id = parseInt(records[j]);
          if (option_id_index_mapper.hasOwnProperty(option_id)) {
            updated_mutex.push(option_id_index_mapper[option_id] + 1);
          }
        }
        current_options[i].mutex = updated_mutex;
      }
    }

    if (option_position === "left" && option_left_index <= 0) {
      //矩阵单选题多选题 至少要一个up选项和一个left选项
      message.warning(`至少一个左侧选项`, 2, () => {});
    } else if (option_position === "up" && option_up_index <= 0) {
      //矩阵单选题多选题 至少要一个up选项和一个left选项
      message.warning(`至少一个顶部选项`, 2, () => {});
    } else if (
      has_matrix_list_2.includes(question_type_val) &&
      tmpList[question_index].answers.length === 1
    ) {
      //矩阵打分题 矩阵滑条题 至少要一个选项
      message.warning(`至少一个选项`, 2, () => {});
    } else {
      tmpList[question_index].answers.splice(confirmed_option_index, 1);
    }
    // 设置最少选项和最多选项
    if (has_multiple_list.includes(question_type_val)) {
      let next_row_answers = tmpList[question_index].answers.length;
      tmpList[question_index].max_option = next_row_answers
        ? next_row_answers
        : 1;
      tmpList[question_index].min_option = 1;
    } else if (has_matrix_list.includes(question_type_val)) {
      let next_row_answers = tmpList[question_index].answers.filter(
        (item) => item.option_position === "up"
      ).length;
      tmpList[question_index].max_option = next_row_answers
        ? next_row_answers
        : 1;
      tmpList[question_index].min_option = 1;
    }
    this.setState({
      question_list: tmpList,
    });
  }
  // 爷爷选项上移
  handleOptionUp(question_index, option_index, option_position) {
    let tmpList = JSON.parse(JSON.stringify(this.state.question_list));
    let confirmed_option_index = option_index;
    if (option_position) {
      //单选题多选题
      let option_left_index = -1;
      let option_up_index = -1;
      for (let i = 0; i < tmpList[question_index].answers.length; i++) {
        if (tmpList[question_index].answers[i].option_position === "left") {
          option_left_index++;
        } else if (
          tmpList[question_index].answers[i].option_position === "up"
        ) {
          option_up_index++;
        }
        if (option_position === "left" && option_left_index === option_index) {
          confirmed_option_index = i;
          break;
        } else if (
          option_position === "up" &&
          option_up_index === option_index
        ) {
          confirmed_option_index = i;
          break;
        }
      }
    }
    if (confirmed_option_index === 0) {
      message.warning(`已经是第一个选项`, 2, () => {});
    } else {
      let tmp = tmpList[question_index].answers[confirmed_option_index];
      tmpList[question_index].answers[confirmed_option_index] =
        tmpList[question_index].answers[confirmed_option_index - 1];
      tmpList[question_index].answers[confirmed_option_index - 1] = tmp;
    }
    this.setState({
      question_list: tmpList,
    });
  }
  // 爷爷选项下移
  handleOptionDown(question_index, option_index, option_position) {
    let tmpList = JSON.parse(JSON.stringify(this.state.question_list));
    let confirmed_option_index = option_index;
    if (option_position) {
      //单选题多选题
      let option_left_index = -1;
      let option_up_index = -1;
      for (let i = 0; i < tmpList[question_index].answers.length; i++) {
        if (tmpList[question_index].answers[i].option_position === "left") {
          option_left_index++;
        } else if (
          tmpList[question_index].answers[i].option_position === "up"
        ) {
          option_up_index++;
        }
        if (option_position === "left" && option_left_index === option_index) {
          confirmed_option_index = i;
          break;
        } else if (
          option_position === "up" &&
          option_up_index === option_index
        ) {
          confirmed_option_index = i;
          break;
        }
      }
    }
    if (confirmed_option_index === tmpList[question_index].answers.length - 1) {
      message.warning(`已经是最后一个选项`, 2, () => {});
    } else {
      let tmp = tmpList[question_index].answers[confirmed_option_index];
      tmpList[question_index].answers[confirmed_option_index] =
        tmpList[question_index].answers[confirmed_option_index + 1];
      tmpList[question_index].answers[confirmed_option_index + 1] = tmp;
    }
    this.setState({
      question_list: tmpList,
    });
  }
  //爷爷设置选项
  handleSetOption(question_index, option_index, option_position) {
    let tmpList = JSON.parse(JSON.stringify(this.state.question_list));
    let confirmed_option_index = option_index;
    if (option_position) {
      //矩阵单选题多选题
      let option_left_index = -1;
      let option_up_index = -1;

      for (let i = 0; i < tmpList[question_index].answers.length; i++) {
        if (tmpList[question_index].answers[i].option_position === "left") {
          option_left_index++;
        } else if (
          tmpList[question_index].answers[i].option_position === "up"
        ) {
          option_up_index++;
        }
        if (option_position === "left" && option_left_index === option_index) {
          confirmed_option_index = i;
          break;
        } else if (
          option_position === "up" &&
          option_up_index === option_index
        ) {
          confirmed_option_index = i;
          break;
        }
      }
    }
    this.setState({
      is_option_config: true,
      currentOptionIndex: confirmed_option_index,
      currentQuestionIndex: question_index,
    });
  }
  // 爷爷处理select
  handleSelectOption(next_selected_question_type) {
    let single_type_list = [
      "single",
      "multiple",
      "sort",
      "proportion",
      "picture",
    ];
    let matrix_type_list = [
      "matrix_single",
      "matrix_multiple",
      "matrix_blank",
      "matrix_score",
      "matrix_nps",
      "matrix_psm",
      "blank_psm",
    ];
    let score_type_list = ["blank", "score", "nps", "enumeration"];
    let currentQuestionIndex = this.state.currentQuestionIndex;
    let current_question_type =
      this.state.question_list[currentQuestionIndex].question_type;
    let current_question_type_val = getQuestionType(current_question_type);
    let next_selected_question_type_val = getQuestionType(
      next_selected_question_type
    );
    let tmpList = JSON.parse(JSON.stringify(this.state.question_list));
    tmpList[currentQuestionIndex].question_type = next_selected_question_type;
    if (single_type_list.includes(current_question_type_val)) {
      // tmpList[currentQuestionIndex].answers = []
      if (next_selected_question_type_val === "multiple") {
        tmpList[currentQuestionIndex].min_option = tmpList[currentQuestionIndex]
          .answers.length
          ? 1
          : 0;
        tmpList[currentQuestionIndex].max_option =
          tmpList[currentQuestionIndex].answers.length || 1;
      }
      if (next_selected_question_type_val === "picture") {
        tmpList[currentQuestionIndex].answers = [];
        let pic_answer_obj1 = {
          name: "同意上传",
          sort: 0,
          image: { name: "", val: "" },
          is_other: false,
          is_option_active: 1,
          option_id: "",
          content: "",
          mutex: [],
        };
        let pic_answer_obj2 = {
          name: "拒绝上传",
          sort: 0,
          image: { name: "", val: "" },
          is_other: false,
          is_option_active: 1,
          option_id: "",
          content: "",
          mutex: [],
        };
        tmpList[currentQuestionIndex].answers.push(pic_answer_obj1);
        tmpList[currentQuestionIndex].answers.push(pic_answer_obj2);
      }
    } else if (matrix_type_list.includes(current_question_type_val)) {
      // 矩阵题
      if (
        next_selected_question_type_val === "matrix_score" ||
        next_selected_question_type_val === "matrix_nps"
      ) {
        tmpList[currentQuestionIndex].min_name = "非常不满意";
        tmpList[currentQuestionIndex].max_name = "非常满意";
        tmpList[currentQuestionIndex].min_value = 1;
        tmpList[currentQuestionIndex].max_value = 5;
        if (tmpList[currentQuestionIndex].answers.length <= 0) {
          tmpList[currentQuestionIndex].answers.push({
            name: "选项",
            sort: 0,
            image: { name: "", val: "" },
            is_other: false,
            is_option_active: 1,
            option_id: "",
            content: "",
            mutex: [],
          });
        } else {
          for (
            let i = 0;
            i < tmpList[currentQuestionIndex].answers.length;
            i++
          ) {
            tmpList[currentQuestionIndex].answers[i].option_position = "";
          }
        }
      } else {
        // 其他矩阵题型
        if (
          current_question_type_val === "matrix_score" ||
          current_question_type_val === "matrix_nps"
        ) {
          for (
            let i = 0;
            i < tmpList[currentQuestionIndex].answers.length;
            i++
          ) {
            tmpList[currentQuestionIndex].answers[i].option_position = "left";
          }
        }
        let row_answers = tmpList[currentQuestionIndex].answers.filter(
          (item) => item.option_position === "left"
        );
        let column_answers = tmpList[currentQuestionIndex].answers.filter(
          (item) => item.option_position === "up"
        );
        if (row_answers.length <= 0) {
          tmpList[currentQuestionIndex].answers.push({
            name: "选项",
            sort: 0,
            image: { name: "", val: "" },
            is_other: false,
            is_option_active: 1,
            option_id: "",
            option_position: "left",
            content: "",
            mutex: [],
          });
        } else if (column_answers.length <= 0) {
          for (let i = 0; i < 5; i++) {
            tmpList[currentQuestionIndex].answers.push({
              name: "选项",
              sort: 0,
              image: { name: "", val: "" },
              is_other: false,
              is_option_active: 1,
              option_id: "",
              option_position: "up",
              content: "",
              mutex: [],
            });
          }
        }
        if (next_selected_question_type_val === "matrix_multiple") {
          tmpList[currentQuestionIndex].min_option = tmpList[
            currentQuestionIndex
          ].answers.filter((item) => item.option_position === "left").length
            ? 1
            : 0;
          tmpList[currentQuestionIndex].max_option =
            tmpList[currentQuestionIndex].answers.filter(
              (item) => item.option_position === "left"
            ).length || 1;
        }
        tmpList[currentQuestionIndex].min_name = 0;
        tmpList[currentQuestionIndex].max_name = 0;
        tmpList[currentQuestionIndex].min_value = 0;
        tmpList[currentQuestionIndex].max_value = 0;
      }
    } else if (score_type_list.includes(current_question_type_val)) {
      if (
        next_selected_question_type_val === "blank" ||
        next_selected_question_type_val === "enumeration"
      ) {
        tmpList[currentQuestionIndex].answers = [];
        tmpList[currentQuestionIndex].answers.push({
          name: "选项",
          sort: 0,
          image: { name: "", val: "" },
          is_other: false,
          is_option_active: 1,
          option_id: "",
          content: "",
          mutex: [],
        });
        tmpList[currentQuestionIndex].min_name = 0;
        tmpList[currentQuestionIndex].max_name = 0;
        tmpList[currentQuestionIndex].min_value = 0;
        tmpList[currentQuestionIndex].max_value = 0;
      } else {
        tmpList[currentQuestionIndex].min_name = "非常不满意";
        tmpList[currentQuestionIndex].max_name = "非常满意";
        tmpList[currentQuestionIndex].min_value = 1;
        tmpList[currentQuestionIndex].max_value = 5;
        if (tmpList[currentQuestionIndex].answers.length <= 0) {
          tmpList[currentQuestionIndex].answers.push({
            name: "选项",
            sort: 0,
            image: { name: "", val: "" },
            is_other: false,
            is_option_active: 1,
            option_id: "",
            content: "",
            mutex: [],
          });
        }
      }
    }
    this.setState(
      {
        question_list: tmpList,
      },
      () => {
        this.handleOptionList();
      }
    );
  }
  // 爷爷添加选项
  handleAddOption(question_index, option_index) {
    let has_multiple_list = ["multiple"];
    let tmpList = JSON.parse(JSON.stringify(this.state.question_list));
    let question_type_val = getQuestionType(
      tmpList[question_index].question_type
    );
    let question_type = tmpList[question_index].question_type;
    let default_answer = {};
    let default_sort =
      option_index >= 0 ? option_index : tmpList[question_index].answers.length;

    if (tmpList[question_index].is_other === 1) {
      //删除的体现是有其他的题型
      default_sort = default_sort - 1;
    }

    if (question_type === 6) {
      //比重
      default_answer = {
        name: "比重2",
        sort: default_sort,
        is_option_active: 1,
        image: { name: "", val: "" },
        option_id: "",
        option_position: "",
        content: "",
        mutex: [],
      };
    } else if (
      question_type === 1 ||
      question_type === 2 ||
      question_type === 4 ||
      question_type === 19
    ) {
      //单选
      default_answer = {
        name: "选项",
        sort: default_sort,
        is_other: false,
        is_option_active: 1,
        image: { name: "", val: "" },
        option_id: "",
        option_position: "",
        content: "",
        mutex: [],
      };
    } else if (question_type === 14 || question_type === 15) {
      //单选
      default_answer = {
        name: "选项",
        sort: default_sort,
        is_other: false,
        is_option_active: 1,
        image: { name: "", val: "" },
        option_id: "",
        option_position: "",
        content: "",
        mutex: [],
      };
    }

    if (tmpList[question_index].is_other === 1) {
      //删除的体现是有其他的题型
      tmpList[question_index].answers.splice(default_sort, 0, default_answer);
    } else if (option_index >= 0) {
      tmpList[question_index].answers.splice(
        option_index + 1,
        0,
        default_answer
      );
    } else {
      tmpList[question_index].answers.push(default_answer);
    }
    if (question_type === 19) {
      if (
        parseFloat(
          tmpList[question_index].max_value * tmpList[question_index].min_value
        ) < tmpList[question_index].answers.length
      ) {
        //任务数选项数与任务数的乘积，应大于等于选项数量
        tmpList[question_index].min_value = 3;
        tmpList[question_index].max_value =
          tmpList[question_index].answers.length;
      }
    }
    if (has_multiple_list.includes(question_type_val)) {
      // 多选题
      let next_row_answers = tmpList[question_index].answers.length;
      tmpList[question_index].max_option = next_row_answers
        ? next_row_answers
        : 1;
      tmpList[question_index].min_option = 1;

      // 处理更新选项互斥，保证正确性
      let current_options = tmpList[question_index].answers; //当前题目的选项
      let option_id_index_mapper = {}; // 选项ID和索引的映射

      for (let i = 0; i < current_options.length; i++) {
        if (current_options[i].option_id) {
          option_id_index_mapper[current_options[i].option_id] = i;
        }
      }
      for (let i = 0; i < current_options.length; i++) {
        // 从记录中重新取出inconsistent_option_ids赋值于mutex
        let updated_mutex = [];
        let records = current_options[i].inconsistent_option_ids
          ? current_options[i].inconsistent_option_ids.split(",")
          : [];
        for (let j = 0; j < records.length; j++) {
          let option_id = parseInt(records[j]);
          if (option_id_index_mapper.hasOwnProperty(option_id)) {
            updated_mutex.push(option_id_index_mapper[option_id] + 1);
          }
        }
        current_options[i].mutex = updated_mutex;
      }
    }
    this.setState({
      question_list: tmpList,
    });
  }
  // 爷爷添加行选项
  handleAddMatrixOption(option_position, question_index) {
    let has_matrix_list = ["matrix_multiple"];
    let tmpList = JSON.parse(JSON.stringify(this.state.question_list));
    let question_type_val = getQuestionType(
      tmpList[question_index].question_type
    );
    let default_answer = {};
    if (option_position === "left") {
      let row_answers = tmpList[question_index].answers.filter(
        (item) => item.option_position === "left"
      );
      let default_row_sort = row_answers.length;
      default_answer = {
        name: "选项",
        sort: default_row_sort,
        image: { name: "", val: "" },
        is_other: false,
        is_option_active: 1,
        option_id: "",
        option_position: "left",
        content: "",
        mutex: [],
      };
      tmpList[question_index].answers.push(default_answer);
    } else {
      let column_answers = tmpList[question_index].answers.filter(
        (item) => item.option_position === "up"
      );
      let default_column_sort = column_answers.length;
      default_answer = {
        name: "选项",
        sort: default_column_sort,
        image: { name: "", val: "" },
        is_other: false,
        is_option_active: 1,
        option_id: "",
        option_position: "up",
        content: "",
        mutex: [],
      };
      tmpList[question_index].answers.push(default_answer);
      if (has_matrix_list.includes(question_type_val)) {
        let next_row_answers = tmpList[question_index].answers.filter(
          (item) => item.option_position === "up"
        ).length;
        tmpList[question_index].max_option = next_row_answers
          ? next_row_answers
          : 1;
        tmpList[question_index].min_option = 1;
      }
    }
    this.setState({
      question_list: tmpList,
    });
  }
  // 爷爷添加选项图片
  addOptionPic(question_index, option_index, image, filename, option_position) {
    let tmpList = JSON.parse(JSON.stringify(this.state.question_list));
    let confirmed_option_index = option_index;
    if (option_position) {
      //单选题多选题
      let option_left_index = -1;
      let option_up_index = -1;
      for (let i = 0; i < tmpList[question_index].answers.length; i++) {
        if (tmpList[question_index].answers[i].option_position === "left") {
          option_left_index++;
        } else if (
          tmpList[question_index].answers[i].option_position === "up"
        ) {
          option_up_index++;
        }
        if (option_position === "left" && option_left_index === option_index) {
          confirmed_option_index = i;
          break;
        } else if (
          option_position === "up" &&
          option_up_index === option_index
        ) {
          confirmed_option_index = i;
          break;
        }
      }
    }
    tmpList[question_index].answers[confirmed_option_index].image.name =
      filename;
    tmpList[question_index].answers[confirmed_option_index].image.val = image;
    this.setState({
      question_list: tmpList,
    });
  }
  // 爷爷选项上下架
  handleActiveOption(option_index, question_index) {
    let tmpList = JSON.parse(JSON.stringify(this.state.question_list));
    tmpList[question_index].answers[option_index].is_option_active =
      1 -
      parseInt(tmpList[question_index].answers[option_index].is_option_active);
    this.setState({
      question_list: tmpList,
    });
  }
  // 爷爷选项描述
  handleOptionInput(val, option_index, question_index) {
    let tmpList = JSON.parse(JSON.stringify(this.state.question_list));
    if (val === "其他") {
      message.warning(`不可输入"其他"二字`, 2, () => {});
      tmpList[question_index].answers[option_index].content = "";
    } else {
      tmpList[question_index].answers[option_index].content = val;
    }
    this.setState({
      question_list: tmpList,
    });
  }
  // 爷爷Topx描述
  handleTopInput(val, option_index, question_index) {
    let tmpList = JSON.parse(JSON.stringify(this.state.question_list));
    tmpList[question_index].answers[option_index].option_position = val;
    this.setState({
      question_list: tmpList,
    });
  }
  // arr1比arr2大 求差集
  subSet(arr1, arr2) {
    var set1 = new Set(arr1);
    var set2 = new Set(arr2);
    var subset = [];
    for (let item of set1) {
      if (!set2.has(item)) {
        subset.push(item);
      }
    }
    return subset;
  }
  // 爷爷选项互斥
  handleMutexOption(key_arr, option_index, question_index) {
    let tmpList = JSON.parse(JSON.stringify(this.state.question_list));
    let tmp_key_arr = key_arr;
    let is_add_flag = false;
    if (
      key_arr.length >
      tmpList[question_index].answers[option_index].mutex.length
    ) {
      is_add_flag = true;
    }
    if (is_add_flag) {
      //如果是添加选项
      for (let i = 0; i < tmp_key_arr.length; i++) {
        let other_index = tmp_key_arr[i] - 1;
        let other_mutex = tmpList[question_index].answers[other_index].mutex;
        if (other_mutex.indexOf(option_index + 1) === -1) {
          // 如果没有则添加
          other_mutex.push(option_index + 1); //数组下标1开始
        }
        tmpList[question_index].answers[other_index].mutex = other_mutex;
      }
    } else {
      // 删除选项
      tmp_key_arr = this.subSet(
        tmpList[question_index].answers[option_index].mutex,
        key_arr
      );
      for (let i = 0; i < tmp_key_arr.length; i++) {
        let other_index = tmp_key_arr[i] - 1;
        let other_mutex = tmpList[question_index].answers[other_index].mutex;
        if (other_mutex.indexOf(option_index + 1) !== -1) {
          // 如果没有则添加
          other_mutex.splice(other_mutex.indexOf(option_index + 1), 1);
        }
        tmpList[question_index].answers[other_index].mutex = other_mutex;
      }
    }
    tmpList[question_index].answers[option_index].mutex = key_arr;
    this.setState({
      question_list: tmpList,
    });
  }
  // 爷爷删除选项图片
  deleteOptionPic(question_index, option_index, option_position) {
    let tmpList = JSON.parse(JSON.stringify(this.state.question_list));
    let confirmed_option_index = option_index;
    if (option_position) {
      //单选题多选题
      let option_left_index = -1;
      let option_up_index = -1;
      for (let i = 0; i < tmpList[question_index].answers.length; i++) {
        if (tmpList[question_index].answers[i].option_position === "left") {
          option_left_index++;
        } else if (
          tmpList[question_index].answers[i].option_position === "up"
        ) {
          option_up_index++;
        }
        if (option_position === "left" && option_left_index === option_index) {
          confirmed_option_index = i;
          break;
        } else if (
          option_position === "up" &&
          option_up_index === option_index
        ) {
          confirmed_option_index = i;
          break;
        }
      }
    }
    tmpList[question_index].answers[confirmed_option_index].image.name = "";
    tmpList[question_index].answers[confirmed_option_index].image.val = "";
    this.setState({
      question_list: tmpList,
    });
  }
  // 爸爸添加题目图片
  addQuestionPic(image, filename, question_index) {
    let tmpList = JSON.parse(JSON.stringify(this.state.question_list));
    tmpList[question_index].image.name = filename;
    tmpList[question_index].image.val = image;
    this.setState({
      question_list: tmpList,
    });
  }
  // 爸爸删除题目图片
  deleteQuestionPic(question_index) {
    let tmpList = JSON.parse(JSON.stringify(this.state.question_list));
    tmpList[question_index].image.name = "";
    tmpList[question_index].image.val = "";
    this.setState({
      question_list: tmpList,
    });
  }
  //父亲删除问题
  deleteQuestion(index) {
    let tmpList = JSON.parse(JSON.stringify(this.state.question_list));
    tmpList.splice(index, 1);
    this.setState({
      currentQuestionIndex: index - 1,
      question_list: tmpList,
    });
  }
  // 父亲复制问题
  handleCopy(index) {
    let tmpList = JSON.parse(JSON.stringify(this.state.question_list));
    let tmpAnswer = JSON.parse(JSON.stringify(tmpList[index]));
    if (String(tmpAnswer.question_id) !== "") {
      tmpAnswer.question_id = "";
    }
    for (let i = 0; i < tmpAnswer.answers.length; i++) {
      tmpAnswer.answers[i].option_id = "";
    }
    let default_question_sort = tmpList.length;
    tmpAnswer.sort = default_question_sort - 1;
    tmpList.splice(index + 1, 0, tmpAnswer);
    // tmpList.splice(index, 1)
    this.setState({
      question_list: tmpList,
    });
  }
  // 操作是否必填
  handleSwitch(key, question_index) {
    if (question_index === "") {
      //config为空
      return;
    }
    let tmpList = JSON.parse(JSON.stringify(this.state.question_list));
    let value = tmpList[question_index][key];
    tmpList[question_index][key] = 1 - value;
    if (key === "is_other") {
      if (tmpList[question_index][key] === 1) {
        //有其他
        let default_sort = tmpList[question_index].answers.length;
        let default_answer = {
          name: "其他",
          sort: default_sort,
          is_other: true,
          is_option_active: 1,
          image: { name: "", val: "" },
          option_id: "",
          option_position: "",
          content: "其他",
          mutex: [],
        };
        tmpList[question_index].answers.push(default_answer);
      } else {
        tmpList[question_index].is_other = 0;
        tmpList[question_index].answers.pop();
      }
    }
    this.setState({
      question_list: tmpList,
    });
  }
  //操作输入框
  handleInput(key, val, index) {
    let tmpList = JSON.parse(JSON.stringify(this.state.question_list));
    tmpList[index][key] = val;
    this.setState({
      question_list: tmpList,
    });
  }

  //问卷设置
  async logicSetting() {
    await this.accomplish("save", "logic_setting");
  }
  // 计算列表
  async calculationSetting() {
    this.setState({
      canShowScoreList: true,
      showQuestionInfo: false,
      canShowLogic: false,
    });
    this.props.getData({
      project_entry_number: 5,
    });
  }
  // 从问卷逻辑到问卷信息 or 从计算设置到问卷信息
  handleQuestionInfo() {
    this.setState({
      showQuestionInfo: true,
      canShowLogic: false,
      canShowScoreList: false,
    });
    this.props.getData({
      project_entry_number: 3,
    });
  }
  // 展示模版
  handleQuestionTemplate() {
    this.setState({
      questionnaire_tamplate_flag: !this.state.questionnaire_tamplate_flag,
    });
  }
  //子组件传给父组件状态 是否显示题型选择
  canShowQuestionTypePopup(object) {
    this.setState(
      {
        showQuestionTypePopup: object.showQuestionTypePopup,
        add_question_type: object.add_question_type,
      },
      () => {
        if (this.state.add_question_type) {
          this.addQuestionnaire();
        }
      }
    );
  }
  // 新增问答
  addQuestionnaire() {
    let tmpList = JSON.parse(JSON.stringify(this.state.question_list));
    let new_question_obj = JSON.parse(
      JSON.stringify(this.state.question_template)
    );
    new_question_obj.question_type = this.state.add_question_type;
    new_question_obj.name = "题目名称";
    new_question_obj.sort = this.state.question_list.length;
    let question_type = this.state.add_question_type;
    let default_answer = {
      name: "选项",
      is_other: false,
      is_option_active: 1,
      image: { name: "", val: "" },
      option_id: "",
      option_position: "",
      content: "",
      mutex: [],
      inconsistent_option_ids: "",
    };
    let question_type_val = getQuestionType(question_type);

    if (question_type_val === "proportion") {
      new_question_obj.max_value = 100;
    }
    if (question_type_val === "multiple") {
      new_question_obj.answers = [default_answer];
      new_question_obj.min_option = 1;
      new_question_obj.max_option = 1;
    } else if (
      question_type_val === "score" ||
      question_type_val === "nps" ||
      question_type_val === "matrix_score" ||
      question_type_val === "matrix_nps"
    ) {
      new_question_obj.answers = [default_answer];
      new_question_obj.min_name = "非常不满意";
      new_question_obj.max_name = "非常满意";
      new_question_obj.min_value = 1;
      new_question_obj.max_value = 5;
    } else if (
      question_type_val === "matrix_single" ||
      question_type_val === "matrix_multiple" ||
      question_type_val === "matrix_blank" ||
      question_type_val === "matrix_psm" ||
      question_type_val === "blank_psm"
    ) {
      new_question_obj.answers = [];

      if (
        question_type_val === "matrix_psm" ||
        question_type_val === "blank_psm"
      ) {
        // 矩阵PSM类型
        new_question_obj.answers.push({
          name: "1000元",
          sort: 0,
          image: { name: "", val: "" },
          is_other: false,
          is_option_active: 1,
          option_id: "",
          option_position: "left",
          content: "",
          mutex: [],
        });
        let top_options = [
          "太便宜了",
          "划得来，会去购买",
          "有些贵，但仍会考虑",
          "太贵了",
        ];
        for (let i = 0; i < 4; i++) {
          new_question_obj.answers.push({
            name: top_options[i],
            sort: 0,
            image: { name: "", val: "" },
            is_other: false,
            is_option_active: 1,
            option_id: "",
            option_position: "up",
            content: "",
            mutex: [],
          });
        }
      } else {
        //矩阵单选多选
        new_question_obj.answers.push({
          name: "选项",
          sort: 0,
          image: { name: "", val: "" },
          is_other: false,
          is_option_active: 1,
          option_id: "",
          option_position: "left",
          content: "",
          mutex: [],
        });
        for (let i = 0; i < 5; i++) {
          new_question_obj.answers.push({
            name: "选项",
            sort: 0,
            image: { name: "", val: "" },
            is_other: false,
            is_option_active: 1,
            option_id: "",
            option_position: "up",
            content: "",
            mutex: [],
          });
        }
      }

      if (question_type_val === "matrix_multiple") {
        new_question_obj.min_option = new_question_obj.answers.filter(
          (item) => item.option_position === "left"
        ).length
          ? 1
          : 0;
        new_question_obj.max_option =
          new_question_obj.answers.filter(
            (item) => item.option_position === "left"
          ).length || 1;
      }
    } else if (question_type_val === "picture") {
      //图片题
      new_question_obj.answers = [];
      let pic_answer_obj1 = {
        name: "同意上传",
        sort: 0,
        image: { name: "", val: "" },
        is_other: false,
        is_option_active: 1,
        option_id: "",
        content: "",
        mutex: [],
      };
      let pic_answer_obj2 = {
        name: "拒绝上传",
        sort: 0,
        image: { name: "", val: "" },
        is_other: false,
        is_option_active: 1,
        option_id: "",
        content: "",
        mutex: [],
      };
      new_question_obj.answers.push(pic_answer_obj1);
      new_question_obj.answers.push(pic_answer_obj2);
    } else if (question_type_val === "max_diff") {
      // 加入默认值
      new_question_obj.answers = [];
      for (let i = 0; i < 8; i++) {
        new_question_obj.answers.push(
          JSON.parse(JSON.stringify(default_answer))
        );
      }
      // 默认的name
      new_question_obj.answers[0].name = "口味";
      new_question_obj.answers[1].name = "健康";
      new_question_obj.answers[2].name = "品牌";
      new_question_obj.answers[3].name = "功能";
      new_question_obj.answers[4].name = "包装";
      new_question_obj.answers[5].name = "保质期";
      new_question_obj.answers[6].name = "配料";
      new_question_obj.answers[7].name = "价格";
      // 默认的value
      new_question_obj.min_name = "最不重要";
      new_question_obj.max_name = "最重要";
      new_question_obj.min_value = 4;
      new_question_obj.max_value = 6;
    } else if (question_type_val === "heatmap") {
      new_question_obj.answers = [];
      default_answer.name = "默认选项";
      new_question_obj.answers = [default_answer];
      new_question_obj.min_option = 1;
      new_question_obj.max_option = 10;
    } else if (question_type_val === "single_2") {
      new_question_obj = {
        question_id: "",
        question_type: 12,
        name: "您是否种草了这款产品?",
        image: { name: "", val: "" },
        sort: 0,
        is_required: 1,
        is_back_allowed: 1,
        is_option_random: 0,
        question_group: 0, //题目组比，默认0
        is_group_random: 0, //题目组别之间是否乱序
        is_within_group_random: 0, //题目组内是否乱序
        is_question_active: 1,
        min_time: 0,
        is_other: 0,
        min_name: "0",
        max_name: "0",
        min_value: 0,
        max_value: 0,
        min_option: 0,
        max_option: 3,
        answers: [
          {
            option_id: "",
            name: "种草",
            sort: 0,
            image: { name: "", val: "" },
            is_other: false,
            is_option_active: 1,
            content: "",
            mutex: [],
          },
          {
            option_id: "",
            name: "一般",
            sort: 1,
            image: { name: "", val: "" },
            is_other: false,
            is_option_active: 1,
            content: "",
            mutex: [],
          },
          {
            option_id: "",
            name: "不感兴趣",
            sort: 2,
            image: { name: "", val: "" },
            is_other: false,
            is_option_active: 1,
            content: "",
            mutex: [],
          },
        ],
      };
    } else if (question_type_val === "single_3") {
      new_question_obj = {
        question_id: "",
        question_type: 13,
        name: "您是否愿意购买这款产品?",
        image: { name: "", val: "" },
        sort: 1,
        is_required: 1,
        is_back_allowed: 1,
        is_option_random: 0,
        question_group: 0, //题目组比，默认0
        is_group_random: 0, //题目组别之间是否乱序
        is_within_group_random: 0, //题目组内是否乱序
        is_question_active: 1,
        min_time: 0,
        is_other: 0,
        min_name: "0",
        max_name: "0",
        min_value: 0,
        max_value: 0,
        min_option: 0,
        max_option: 3,
        answers: [
          {
            option_id: "",
            name: "有下单意愿",
            sort: 0,
            image: { name: "", val: "" },
            is_other: false,
            is_option_active: 1,
            content: "",
            mutex: [],
          },
          {
            option_id: "",
            name: "需要时再考虑",
            sort: 1,
            image: { name: "", val: "" },
            is_other: false,
            is_option_active: 1,
            content: "",
            mutex: [],
          },
          {
            option_id: "",
            name: "优惠时再考虑",
            sort: 2,
            image: { name: "", val: "" },
            is_other: false,
            is_option_active: 1,
            content: "",
            mutex: [],
          },
        ],
      };
    } else if (question_type_val === "nps_2") {
      new_question_obj = {
        question_id: "",
        question_type: 11,
        name: "您在多大程度上愿意向您的朋友或同事推荐这个产品?",
        image: { name: "", val: "" },
        sort: 2,
        is_required: 1,
        is_back_allowed: 1,
        is_option_random: 0,
        question_group: 0, //题目组比，默认0
        is_group_random: 0, //题目组别之间是否乱序
        is_within_group_random: 0, //题目组内是否乱序
        is_question_active: 1,
        min_time: 0,
        is_other: 0,
        min_name: "强烈不推荐",
        max_name: "强烈推荐",
        min_value: 1,
        max_value: 10,
        min_option: 0,
        max_option: 0,
        answers: [],
      };
    } else {
      new_question_obj.answers = [default_answer];
    }

    tmpList.push(new_question_obj);
    let currentQuestionIndex = tmpList.length - 1;

    let question_config_tab_arr = this.state.question_config_tab_arr;
    question_config_tab_arr.forEach((ele) => {
      if (ele.name === "题目设置") {
        ele.is_show = true;
      } else {
        ele.is_show = false;
      }
    });
    this.setState(
      {
        question_list: tmpList,
        currentQuestionIndex: currentQuestionIndex,
        currentOptionIndex: -1,
        is_option_config: false,
        question_config_tab: 1,
        question_config_tab_arr: question_config_tab_arr,
      },
      () => {
        this.handleOptionList();
      }
    );
  }
  // 处理问题可切换选项
  handleOptionList() {
    let currentQuestionIndex = this.state.currentQuestionIndex;
    let current_question_type =
      this.state.question_list[currentQuestionIndex].question_type;
    let current_question_type_val = getQuestionType(current_question_type);
    let option_list = this.state.option_list;
    let single_type_list = [
      "single",
      "multiple",
      "sort",
      "proportion",
      "picture",
    ];
    let matrix_type_list = [
      "matrix_single",
      "matrix_multiple",
      "matrix_blank",
      "matrix_score",
      "matrix_nps",
      "matrix_psm",
      "blank_psm",
    ];
    let maxdiff_type_list = ["max_diff"];
    let heatmap_type_list = ["heatmap"];
    let score_type_list = ["blank", "score", "nps", "enumeration"];
    if (single_type_list.includes(current_question_type_val)) {
      option_list = [
        { value: "单选", key: 1 },
        { value: "多选", key: 2 },
        { value: "排序", key: 4 },
        { value: "比重题", key: 6 },
        { value: "图片题", key: 10 },
      ];
    } else if (matrix_type_list.includes(current_question_type_val)) {
      option_list = [
        { value: "矩阵单选题", key: 8 },
        { value: "矩阵多选题", key: 9 },
        { value: "矩阵打分题", key: 14 },
        { value: "矩阵滑条题", key: 15 },
        { value: "开放题", key: 16 },
        { value: "价格敏感度", key: 22 },
        { value: "PSM填空题", key: 23 },
      ];
    } else if (score_type_list.includes(current_question_type_val)) {
      option_list = [
        { value: "简答题", key: 3 },
        { value: "打分题", key: 5 },
        { value: "滑条题", key: 7 },
        { value: "例举题", key: 18 },
      ];
    } else if (maxdiff_type_list.includes(current_question_type_val)) {
      option_list = [{ value: "MaxDiff", key: 19 }];
    } else if (heatmap_type_list.includes(current_question_type_val)) {
      option_list = [{ value: "热力图", key: 20 }];
    }
    this.setState({
      option_list: option_list,
    });
  }
  // 切换题库or题目设置
  handleQuestionConfigTab(index) {
    let question_config_tab_arr = this.state.question_config_tab_arr;
    if (question_config_tab_arr[index].is_show) {
      return;
    }
    if (
      index === 1 &&
      this.state.currentQuestionIndex === -1 &&
      localStorage.getItem("current_project_type") === "2"
    ) {
      return;
    }
    question_config_tab_arr.forEach((ele, index2) => {
      if (index === index2) {
        ele.is_show = true;
      } else {
        ele.is_show = false;
      }
    });
    this.setState({
      question_config_tab_arr: question_config_tab_arr,
      question_config_tab: index,
    });
  }
  // 添加模板题目
  addTemplateQuestion(item) {
    if (!this.canAddQuestion()) {
      return;
    }
    if (!this.canAddSelectedQuestion(item.question_type)) {
      return;
    }
    let tmpList = JSON.parse(JSON.stringify(this.state.question_list));
    item.question_id = "";
    item.answers.forEach((item) => (item.option_id = ""));
    tmpList.push(item);
    let currentQuestionIndex = tmpList.length - 1;
    this.setState(
      {
        question_list: tmpList,
        currentQuestionIndex: currentQuestionIndex,
        currentOptionIndex: -1,
        is_option_config: false,
      },
      () => {
        this.handleOptionList();
      }
    );
  }
  // 题库和ai survey公用一个接口
  handleQuestionBankTree(treeData1) {
    let treeData = JSON.parse(JSON.stringify(treeData1));
    if (!treeData || treeData.length === 0) {
      this.setState({ ai_selected_survey_option: [] });
    } else {
      let ai_selected_survey_option = [];
      treeData.forEach((item, index) => {
        if (item.name === "通用" || item.title === "通用") {
          ai_selected_survey_option = item.children;
        }
      });
      ai_selected_survey_option.forEach((item, index) => {
        item["key"] = item.id;
        item.value = item.name;
        item.disabled = false;
      });
      this.setState({ ai_selected_survey_option: ai_selected_survey_option });
    }
  }
  render() {
    return (
      <div
        className={
          this.state.project_entry_number >= 3
            ? "QuestionnaireSetting"
            : "QuestionnaireSetting isHide"
        }
      >
        {this.state.loading <= 0 && this.state.loading_save <= 0 ? (
          ""
        ) : (
          <Loading loadingText={this.state.loadingText}></Loading>
        )}
        <div
          className={
            this.state.project_entry_number === 3
              ? "QuestionnaireSetting_container"
              : "isHide"
          }
        >
          {/* <img src={this.state.img1} alt="" /> */}
          <div className="QuestionnaireSetting_main">
            <DragDropContext
              onDragEnd={(param) => {
                const srcI = param.source.index;
                const desI = param.destination?.index;

                if (desI >= 0) {
                  let question_list = JSON.parse(
                    JSON.stringify(this.state.question_list)
                  );
                  // 基础权限用户不可
                  if (
                    this.props.is_native &&
                    question_authority_obj.rule_2[
                      this.props.user.account_type
                    ] &&
                    this.state.has_fixed_list.includes(
                      question_list[desI].question_type
                    ) &&
                    desI < 3
                  ) {
                    message.warning(
                      `基础共创不可以改变产品问卷前三题的位置`,
                      2,
                      () => {}
                    );
                    return;
                  }
                  question_list.splice(
                    desI,
                    0,
                    question_list.splice(srcI, 1)[0]
                  );
                  this.setState({
                    question_list: question_list,
                    currentQuestionIndex: desI,
                  });
                  if (
                    this.state.has_fixed_list.includes(
                      question_list[srcI].question_type
                    ) ||
                    this.state.has_fixed_list.includes(
                      question_list[desI].question_type
                    )
                  ) {
                    this.setState({
                      showDefaultLogic: true, // 是否前三项的位置进行了变更
                    });
                  }
                }
              }}
            >
              <div className="setting_left">
                <Droppable droppableId="droppable-1">
                  {(provided, _) => (
                    <div>
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {this.state.question_list.map((item, index) => {
                          return (
                            // 答卷信息
                            <QuestionInfo
                              key={index}
                              dataList={item}
                              index={index}
                              currentOptionIndex={this.state.currentOptionIndex}
                              is_native={this.props.is_native}
                              editQuestionInfo={this.editQuestionInfo.bind(
                                this
                              )}
                              handleQuestionName={this.handleQuestionName.bind(
                                this
                              )}
                              handleQuestionOption={this.handleQuestionOption.bind(
                                this
                              )}
                              handleQuestionOptionName={this.handleQuestionOptionName.bind(
                                this
                              )}
                              handleDeleteOption={this.handleDeleteOption.bind(
                                this
                              )}
                              handleOptionUp={this.handleOptionUp.bind(this)}
                              handleOptionDown={this.handleOptionDown.bind(
                                this
                              )}
                              handleSetOption={this.handleSetOption.bind(this)}
                              handleAddOption={this.handleAddOption.bind(this)}
                              handleAddMatrixOption={this.handleAddMatrixOption.bind(
                                this
                              )}
                              deleteQuestion={this.deleteQuestion.bind(this)}
                              handleCopy={this.handleCopy.bind(this)}
                              addOptionPic={this.addOptionPic.bind(this)}
                              deleteOptionPic={this.deleteOptionPic.bind(this)}
                              handleActiveOption={this.handleActiveOption.bind(
                                this
                              )}
                              addQuestionPic={this.addQuestionPic.bind(this)}
                              deleteQuestionPic={this.deleteQuestionPic.bind(
                                this
                              )}
                              currentQuestionIndex={
                                this.state.currentQuestionIndex
                              }
                            ></QuestionInfo>
                          );
                        })}
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>

                {/* 没有问题时候的显示 */}
                <div
                  className={
                    this.state.currentQuestionIndex === -1 &&
                    localStorage.getItem("current_project_type") === "2"
                      ? "setting-notice"
                      : "isHide"
                  }
                >
                  请点击新增问答开始设计问卷～
                </div>
                <div className="setting_btn">
                  {/* <div onClick={this.questionnaire_tamplate_isShow.bind(this)}>问卷模板</div> */}
                  {/* <div style={{ cursor: 'not-allowed' }}>问卷模板</div> */}
                  <div onClick={this.clickAddQuestionnaire.bind(this)}>
                    新增问答
                  </div>
                </div>
                <div
                  className={
                    this.state.questionnaire_tamplate_flag ? "" : "isHide"
                  }
                >
                  <QuestionnaireTamplate
                    handleQuestionTemplate={this.handleQuestionTemplate.bind(
                      this
                    )}
                  ></QuestionnaireTamplate>
                </div>
              </div>
            </DragDropContext>
            <div className="setting-right">
              <div className="setting-right-panel">
                <div className="setting-right-panel-tab-wrapper">
                  {this.state.question_config_tab_arr.map((item, index) => {
                    return (
                      <div
                        className={`setting-right-panel-tab ${
                          item.is_show ? "setting-right-panel-tab-active" : ""
                        } 
                                                    ${
                                                      this.state
                                                        .currentQuestionIndex ===
                                                        -1 &&
                                                      String(
                                                        localStorage.getItem(
                                                          "current_project_type"
                                                        )
                                                      ) === "2" &&
                                                      index === 1
                                                        ? "setting-right-panel-tab-not-allowed "
                                                        : ""
                                                    }
                                                `}
                        key={index}
                        onClick={this.handleQuestionConfigTab.bind(this, index)}
                      >
                        {item.name}
                      </div>
                    );
                  })}
                </div>
                <div
                  className={
                    this.state.question_config_tab === 0
                      ? "setting-right-panel-content"
                      : "isHide"
                  }
                >
                  <QuestionTemplate
                    project_entry_number={this.state.project_entry_number}
                    product_id_for_template={this.state.product_id_for_template}
                    addTemplateQuestion={this.addTemplateQuestion.bind(this)}
                    handleQuestionBankTree={this.handleQuestionBankTree.bind(
                      this
                    )}
                  ></QuestionTemplate>
                </div>
                <div
                  className={
                    this.state.question_config_tab === 1
                      ? "setting-right-panel-content"
                      : "isHide"
                  }
                >
                  {/* 答卷配置 */}
                  <QuestionConfig
                    currentQuestionIndex={this.state.currentQuestionIndex}
                    currentOptionIndex={this.state.currentOptionIndex}
                    is_option_config={this.state.is_option_config}
                    dataList={
                      this.state.question_list[this.state.currentQuestionIndex]
                    }
                    handleSwitch={this.handleSwitch.bind(this)}
                    handleInput={this.handleInput.bind(this)}
                    logicSetting={this.logicSetting.bind(this)}
                    calculationSetting={this.calculationSetting.bind(this)}
                    handleSelectOption={this.handleSelectOption.bind(this)}
                    handleActiveOption={this.handleActiveOption.bind(this)}
                    handleOptionInput={this.handleOptionInput.bind(this)}
                    handleTopInput={this.handleTopInput.bind(this)}
                    handleMutexOption={this.handleMutexOption.bind(this)}
                    option_list={this.state.option_list}
                    className="isHide"
                  ></QuestionConfig>
                </div>
                <div
                  className={
                    this.state.question_config_tab === 2
                      ? "setting-right-panel-content"
                      : "isHide"
                  }
                >
                  {/* 答卷配置 */}
                  <QuestionAi
                    survey_id={this.state.survey_id || this.props.survey_id}
                    project_entry_number={this.state.project_entry_number}
                    addTemplateQuestion={this.addTemplateQuestion.bind(this)}
                    ai_selected_survey_option={
                      this.state.ai_selected_survey_option
                    }
                  ></QuestionAi>
                </div>
              </div>
            </div>
          </div>
          <div className="operaion-row" id="operaion-row">
            <div onClick={this.prev.bind(this)}>上一步</div>
            <div onClick={this.accomplish.bind(this)}>保存</div>
            <div onClick={this.accomplish.bind(this, "next")}>
              {parseInt(localStorage.getItem("current_project_type")) === 1
                ? "下一步"
                : "完成"}
            </div>
          </div>
        </div>
        {/* 类似vue v-if */}
        {!this.state.showQuestionInfo && this.state.canShowScoreList ? (
          <ScoreList
            question_list={this.state.question_list}
            showQuestionInfo={this.state.showQuestionInfo}
            canShowScoreList={this.state.canShowScoreList}
            handleQuestionInfo={this.handleQuestionInfo.bind(this)}
          ></ScoreList>
        ) : (
          ""
        )}
        {!this.state.showQuestionInfo && this.state.canShowLogic ? (
          <QuestionLogic
            question_list={this.state.question_list}
            survey_id={this.state.survey_id}
            is_native={this.props.is_native}
            showDefaultLogic={this.state.showDefaultLogic}
            showQuestionInfo={this.state.showQuestionInfo}
            handleQuestionInfo={this.handleQuestionInfo.bind(this)}
          ></QuestionLogic>
        ) : (
          ""
        )}
        {/* 类似 回到上一步也会get 一下 */}
        {/* <div className={this.state.showQuestionInfo ? "isHide" : ""} >
                    <QuestionLogic question_list={this.state.question_list}
                        survey_id={this.state.survey_id}
                        showQuestionInfo={this.state.showQuestionInfo}
                        handleQuestionInfo={this.handleQuestionInfo.bind(this)}>
                    </QuestionLogic>
                </div> */}
        {this.state.showQuestionTypePopup ? (
          <QuestionTypePopup
            canShowQuestionTypePopup={this.canShowQuestionTypePopup.bind(this)}
            canAddSelectedQuestion={this.canAddSelectedQuestion.bind(this)}
          ></QuestionTypePopup>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }), //映射状态
  { prioritizeSurveyQueueItem } //映射操作状态的方法
)(QuestionnaireSetting);
